<template>
    <div>
      <b-overlay :show="loadingCalendarUrl">
        <b-card header="Click either Maths or English blue button to book your teaching session day and time">
          <b-table-simple hover striped>
            <b-tbody>
              <template v-for="a, idx of appointmentsWithEmptySlots">
                <iris-appointment-slot v-if="a" :key="a._id" :name="`Session ${idx + 1}`"
                :appointment="a" @delete="cancelEvent(a)" @reschedule="rescheduleEvent" :show-connect-link="showConnectLinks && !isDedicatedTutor" :hide-reschedule-link="isDiamondSharedUrl(a.bookingUrl)" />
                <b-tr v-else :key="idx">
                  <b-td>
                    Book Session {{ idx + 1 }}
                  </b-td>
                  <b-td colspan="2">
                    <b-button v-for="{ subject, icon }, index of calendlyUrls" :key="subject" @click="handleClick(index)" variant="primary" class="mt-1 mr-1" v-b-tooltip.hover :title="`Book ${subject} teaching session ${ idx + 1}`">
                      <b-icon v-if="icon" :icon="icon" /> {{ subject }}
                    </b-button>
                  </b-td>
              </b-tr>              
              </template>
            </b-tbody>
          </b-table-simple>
        </b-card>
      </b-overlay>
      <div class="overlayText text-dark bg-warning" v-if="interval && calendlyWidgetActive">
        Appointments must be scheduled between <b>{{ interval.start.setLocale('en-gb').toLocaleString(DateTime.DATE_FULL) }}</b> and <b>{{ interval.end.setLocale('en-gb').toLocaleString(DateTime.DATE_FULL) }}</b> otherwise,<br /> <i>they will be cancelled shortly after booking</i>.
        <!-- Please select a date within the range of {{ interval.start.setLocale('en-gb').toLocaleString() }} to {{ interval.end.setLocale('en-gb').toLocaleString() }} -->
      </div>
      <b-modal v-if="interval" v-model="modalVisible" ref="booking-dates-warning-modal" title="Important: Available Booking Dates" ok-title="OK, I Understand" ok-only no-close-on-backdrop no-close-on-esc hide-header-close>
        <p>
          Sessions must be scheduled within the available time slots for the selected billing month,
          from <strong>{{ interval.start.setLocale('en-gb').toLocaleString(DateTime.DATE_FULL) }}</strong> to <strong>{{ interval.end.setLocale('en-gb').toLocaleString(DateTime.DATE_FULL) }}</strong>.
        </p>
        <p>
          If you select a date outside of this range, your booking will be cancelled, and you will be notified.
          Please review the available dates carefully before confirming your booking.
        </p>
      </b-modal>
    </div>
</template>

<script setup lang="ts">

import { DateTime, Interval } from 'luxon'
import { useFetch } from '@/utils/auth'
import Calendly from 'node-calendly'
import { useCalendly } from '~~/calendly/composables/useCalendly'
import { useCalendlyEventListener, activeCalendlyComponent } from '~~/calendly/composables/useCalendlyEventListener'
import { SubscriberPost } from '~~/server/api/appointmnts/[subscriberId].post'
import { AppointmentSlotInterface } from '~~/server/models/appointmentSlot'
import { stringify } from 'qs'
import { getAuthHeaders } from '~~/utils/authorisation'
import { CalendlyGetCustomCalendarsDocument, CalendlyGetCustomCalendarsQuery, CalendlyGetCustomCalendarsQueryVariables, CalendlySetFirstAppointmentDocument, CalendlySetFirstAppointmentMutation, CalendlySetFirstAppointmentMutationVariables, CalendlyTutorSubject, TutorCalendarsFragment, useCalendlyGetCustomCalendarsLazyQuery, useCalendlySetFirstAppointmentMutation } from '~~/iris/src/queries'
import { useApolloClient } from '@vue/apollo-composable'
import { ApolloError } from '@apollo/client'

const instance = getCurrentInstance().proxy
const context = useNuxtApp()
const calendlyWidgetActive = ref(false)

export type AppointmentBlockProps = {
        /** subscriber id */
        subscriberId: number
        /** subscribers email */
        email: string
        /** first name */
        firstName: string
        /** last name  */
        lastName: string
        /** number of slots available */
        slotsAvailable: number
        /** time period allowed */
        interval?: Interval
        /** optional invoice id */
        invoiceId?: string
        /** plan string */
        planCode: string
        /** show iris connect links */
        showConnectLinks?: boolean
        /** fetchCalendarsPromise */
        customCalendersPromise?: Promise<TutorCalendarsFragment>
}
    const props = defineProps<AppointmentBlockProps>()

    const emits = defineEmits<{
      (event: 'slotsUnbooked', numberOfSlotsAvailable: number): void,
      (event: 'customCalendersSet', calender: Promise<TutorCalendarsFragment> | undefined): void
    }>()

    const fetchCalendarsPromise = ref<Promise<TutorCalendarsFragment> | undefined>()
    watch(toRef(props, 'customCalendersPromise'), (v) => {
      fetchCalendarsPromise.value = v
    }, {
      immediate: true
    })
    watch(fetchCalendarsPromise, (v) => {
      if (v !== props.customCalendersPromise) {
        emits('customCalendersSet', v)
      }
    })
    
    const { data: appointments, execute: refreshAppointments } = useFetch(computed(() => `/api/appointmnts/${props.subscriberId}?${stringify({
      ...props.invoiceId ? {
        invoiceId: props.invoiceId
      }: {},
      ...props.interval ? {
        start: props.interval.start.toISO(),
        end: props.interval.end.toISO()
      } : {}
    })}`), {
        initialData: [],
  afterFetch(ctx) {
    // console.log(ctx.data)
    ctx.data = ctx.data.map(d => ({
      ...d,
      startTime: new Date(d.startTime)
    }))
    return ctx
  },
}).json<AppointmentSlotInterface[]>()

const unbookedSlotsAvailable = computed(() => Math.max(props.slotsAvailable - appointments.value.length, 0))

watch(unbookedSlotsAvailable, (newV, oldV) => {
  if (newV !== oldV) {
    emits('slotsUnbooked', newV)
  }
}, {
  immediate: true
})

/**
 * Is the url the same as the diamond shared url calendlars
 * @param url calendly booking url
 */
const isDiamondSharedUrl = (url: string): boolean => {
  return isDedicatedTutor.value && url && calendlyUrls.value.some(v => v.url === url)
}

const appointmentsWithEmptySlots = computed<Array<AppointmentSlotInterface | false>>(() => {
  return [...appointments.value, ...new Array(unbookedSlotsAvailable.value)]
})

const loadingCalendarUrl = ref(false)

const cancelEvent = async (appt: AppointmentSlotInterface) => {
  return $fetch(`/api/appointmnts/${appt.subscriberId}/${appt.eventId}`, {
    method: 'DELETE',
    headers: await getAuthHeaders(context.$auth)
  }).then(() => refreshAppointments())
}

/**
 * is the user having a dedicated tutor use different calendars
 */
const isDedicatedTutor = computed<boolean>(() => props.planCode === 'ELD' || props.planCode === 'STS')

const calendlyUrls = computed<[
  {subject: string; url: string, icon?: string}
]>(() => {
  if (config.public.calendlyDedicatedUrl && config.public.calendlyDedicatedUrl.length && isDedicatedTutor.value) {
    return config.public.calendlyDedicatedUrl
  }
  if (config.public.calendlyPremiumUrl && config.public.calendlyPremiumUrl.length && props.planCode.endsWith('-PM')) {
    return config.public.calendlyPremiumUrl
  }
  if (typeof config.public.calendlyUrl === 'string') {
    return [{
      url: config.public.calendlyUrl,
      subject: 'Tutor Session',
      icon: 'calendar'
    }]
  }
  return config.public.calendlyUrl
})

/** currently selected index for listeners */
let currentSelectedIndex: number
/** current booking url */
let currentBookingUrl: string

if ((process as any).client) {
  useCalendlyEventListener({
      onEventScheduled: async event => {
        if (activeCalendlyComponent.value !== (instance as any)._uid) {
          return
        }
        activeCalendlyComponent.value = undefined
        // console.log('onEventScheduled', event)
        // events.value.push(event.data)
        const id = Calendly.getUuidFromUri((event.data.payload as any).event.uri)
        const invitee = Calendly.getUuidFromUri((event.data.payload as any).invitee.uri)
        await calendly.closePopupWidget()
        const resp = await $fetch(`/api/appointmnts/${props.subscriberId}`, {
          method: 'POST',
          headers: await getAuthHeaders(context.$auth),
          body: {
            eventId: id,
            inviteeId: invitee,
            email: props.email,
            ...props.interval ? {
              minDate: props.interval.start.toISO(),
              maxDate: props.interval.end.toISO()
            } : {},
            ...props.invoiceId ? {
              invoiceId: props.invoiceId
            } : {},
            subjectLabel: calendlyUrls.value[currentSelectedIndex].subject,
            bookingUrl: currentBookingUrl
          } as SubscriberPost
        }).then(() => refreshAppointments()).catch(e => {
            if (e.name === 'FetchError' && e.response.status === 400) {
                return instance.$bvModal.msgBoxOk(e.data.message, {
                  title: 'Appointment outside of period'
                })
            }
            throw e  
        })
        if (isDedicatedTutor.value) {
          /** current selected subject */
          const selectedSubject = calendlyUrls.value[currentSelectedIndex].subject.toLowerCase()[0] === 'm' ? CalendlyTutorSubject.Mathematics : CalendlyTutorSubject.English
          // if no current fetch custom calendars promise OR there is no custom url for the calendar
          if (!fetchCalendarsPromise.value || (await fetchCalendarsPromise.value).customCalendars.every(c => c.subject !== selectedSubject)) {
            // save in async method won't get here if error
            fetchCalendarsPromise.value = apollo.client.mutate<CalendlySetFirstAppointmentMutation, CalendlySetFirstAppointmentMutationVariables>({
              mutation: CalendlySetFirstAppointmentDocument,
              variables: {
                eventUUID: id,
                subject: selectedSubject,
                subscriberId: props.subscriberId,
                otherSubjectCalendarUri: calendlyUrls.value[1 - currentSelectedIndex].url // other url
              }
            }).then(r => r.data.calendlySetFirstAppointment)
          }
        }  
        // const resp = await $fetch(`/api/event/${id}`)
        // console.log(resp)
        // events.value.push(resp)
        // const respInvitee = await $fetch(`/api/event/${id}/invitees/${invitee}`)
        // events.value.push(respInvitee)
        // console.log(id)
      },
      // onEventTypeViewed: event => {
      //   console.log('onEventTypeViewed', event)
      //   events.value.push(event.data)
      //   // console.log(Calendly.getUuidFromUri((event.data.payload as any).uri))
      // },
      // onProfilePageViewed: event => {
      //   console.log('onProfilePageViewed', event)
      //   events.value.push(event.data)
      //   // console.log(Calendly.getUuidFromUri((event.data.payload as any).uri))
      // },
    })
}


const calendly = useCalendly()

const config = useRuntimeConfig()

/** for reschedule need to know when calendly goes away */
let observer: MutationObserver

onUnmounted(() => {
  observer?.disconnect()
  if (activeCalendlyComponent.value === (instance as any)._uid) {
    return calendly.closePopupWidget()
  }
})

onMounted(() => {
  observer = new MutationObserver((record) => {
    for (const mutation of record) {
      for (const el of mutation.removedNodes) {
        if (el instanceof HTMLElement) {
          if (el.classList.contains('calendly-overlay')) {
            calendlyWidgetActive.value = false
            return
          }
        }
      }
      if (activeCalendlyComponent.value === (instance as any)._uid) {
        for (const el of mutation.addedNodes) {
          if (el instanceof HTMLElement) {
            if (el.classList.contains('calendly-overlay')) {
              calendlyWidgetActive.value = true
              return
            }
          }
        }
      }
    }
  })
  // start watching
  observer.observe(document.body, {
      childList: true
  })
})

const rescheduleEvent = (url: string, subjectLabel: string) => {
  const watcher = watch(calendlyWidgetActive, (v) => {
    if (!v) {
      watcher()
      refreshAppointments()
    }
  })
  const urlIndex = calendlyUrls.value.findIndex(u => u.subject.toLowerCase()[0] === subjectLabel.toLowerCase()[0])
  return handleClick(urlIndex, url)
}

const apollo = useApolloClient()



const getPersonalTutorCalendarUrl = (sharedUrl: string, subject: 'm' | 'e'): Promise<string | undefined> => {
  if (subject === 'm' || subject === 'e') {
      loadingCalendarUrl.value = true
      if (!fetchCalendarsPromise.value) {
        fetchCalendarsPromise.value = apollo.client.query<CalendlyGetCustomCalendarsQuery, CalendlyGetCustomCalendarsQueryVariables>({query: CalendlyGetCustomCalendarsDocument, fetchPolicy: 'no-cache', variables: {subscriberId: props.subscriberId}}).then(result => {
          return result.data.calendlyGetCustomCalendars
        })
      }
      return fetchCalendarsPromise.value.then(result => {
        const selectedCalendar = result.customCalendars.find(c => c.subject === (subject === 'e' ? CalendlyTutorSubject.English : CalendlyTutorSubject.Mathematics))
        if (selectedCalendar) {
          return selectedCalendar.calendlyUrl
        }
        return sharedUrl
      }).catch(async (e) => {
        fetchCalendarsPromise.value = undefined
        if (e instanceof ApolloError && e.graphQLErrors[0]?.extensions?.code === 'TutorNameNotFoundInCalendlyError') {
          await instance.$bvModal.msgBoxOk(`${e.message} - please contact Estia Tuition customer support.`, {
            title: 'Estia Tuition teacher not found'
          })
          return undefined
        } else {
          console.error(e)
          return sharedUrl
        }
      }).finally(() => {
        loadingCalendarUrl.value = false
      })
  }
  return Promise.resolve(sharedUrl)
}

// code related to initial warning about dialog box

/** has the modal been shown once? */
const modalShownOnce = ref(false)
/** trigger to show this one */
const modalVisible = ref(false)

/** popup initial warning about dates - if in extra sesisons and no interval don't show */
const initalWarningAboutDates = (): Promise<any> => {
  if (!props.interval || modalShownOnce.value) {
    return Promise.resolve()
  }
  modalVisible.value = true
  modalShownOnce.value = true
  return new Promise((resolve) => {
    const stopWatch = watch(modalVisible, (v) => {
      if (!v) {
        stopWatch()
        return resolve(true)
      }
    })
  })
}

/**
 * 
 * @param urlIndexOrUrl string for rescheduling of index to lookup from base settings
 */
const handleClick = async (urlIndex: number, url?: string) => {
  currentSelectedIndex = urlIndex
  if (!url) {
    url = await getPersonalTutorCalendarUrl(calendlyUrls.value[urlIndex].url, calendlyUrls.value[urlIndex].subject.toLowerCase().charAt(0) as 'm' | 'e')
  }
  if (url) {
    await initalWarningAboutDates()
    currentBookingUrl = url
    activeCalendlyComponent.value = (instance as any)._uid
    return calendly.initPopupWidget({
      prefill: {
        email: props.email,
        firstName: props.firstName,
        lastName: props.lastName,
        name: `${props.firstName} ${props.lastName}`,
        date: props.interval ? props.interval.start.toJSDate() : undefined,
        customAnswers: {
          a1: `${props.subscriberId}`,
          a2: `${props.planCode} - ${appointments.value.length + 1}/${props.slotsAvailable}${props.invoiceId ? ` Extra Sessions` : ''}`
        }
      },
      pageSettings: {
        hideEventTypeDetails: true,
        hideGdprBanner: true,
        hideLandingPageDetails: true,
      },
      url: `${url}?hide_gdpr_banner=1${props.interval ? `&month=${props.interval.start.toISODate().substring(0,7)}` : ''}`
    }).catch(e => {
      return instance.$bvModal.msgBoxOk(`${e.message} - please contact Estia Tuition customer support.`, {
            title: 'Unable to initialise Calendly'
      })
    })
  }
}



</script>


<style scoped>
.overlayText {
  position: fixed;
  top: max(calc(50vh - 345px), 40px);
  left: 50%;
  width: 798px;
  border-radius: 8px;
  color: black;
  font-size: 16px;
  justify-content: center;
  text-align: center;
  align-items: center;
  z-index: 100000;
  padding: 5px;
  box-sizing: border-box;
  transform: translateX(-50%);
  border: solid 1px black;
}

@media(max-width: 1250px) {
  .overlayText {
    width: 678px;
  }
}

@media(max-width: 975px) {
  .overlayText {
    top: 56px;
    max-width: 100%;
  }
}

@media(max-width: 650px) {
  .overlayText {
    border: none;
    border-radius: 0px;
    font-size: 12px;
    top: 0px;
    left: 0px;
    transform: none;
    width: calc(100% - 40px);
  }
}

</style>